module.exports.selectable = ["es", "en", "tl", "zh", "ja", "th", "vi", "hy"];

module.exports.languages = [
  {code: "en", name: "English", native: "English"},
  {code: "es", name: "Spanish", native: "Español"},
  {code: "zh", name: "Chinese", native: "中文"},
  {code: "tl", name: "Tagalog", native: "Tagalog"},
  {code: "ar", name: "Arabic", native: "العربية"},
  {code: "de", name: "German", native: "Deutsch"},
  {code: "fr", name: "French", native: "Français"},
  {code: "ru", name: "Russian", native: "Русский"},
  {code: "fa", name: "Persian", native: "فارسی"},
  {code: "he", name: "Hebrew", native: "עברית"},
  {code: "ja", name: "Japanese", native: "日本語"},
  {code: "ko", name: "Korean", native: "한국어"},
  {code: "th", name: "Thai", native: "ไทย"},
  {code: "tr", name: "Turkish", native: "Türkçe"},
  {code: "vi", name: "Vietnamese", native: "Tiếng Việt"},
  {code: "km", name: "Khmer", native: "ភាសាខ្មែរ"},
  {code: "hy", name: "Armenian", native: "հայերեն"},
];
