import i18n from "i18next";
import {I18n, Translate} from "next-translate";
import setLanguage from "next-translate/setLanguage";
import TransText from "next-translate/TransText";
import nextUseTranslation from "next-translate/useTranslation";
import {useMemo} from "react";

import {I18nNamespace} from "./i18n-namespaces";
import Trans from "./Trans";

type TranslateOptions = Parameters<I18n["t"]>[2];
type I18nKey = Parameters<I18n["t"]>[0];

const addDefaultToOptions = (options: TranslateOptions, i18nKey: I18nKey) => ({
  ...options,
  default: options?.default || (typeof i18nKey === "string" && i18nKey) || undefined,
});

const fixInterpolation =
  (t: Translate): Translate =>
  (i18nKey, query, options) =>
    t(i18nKey, query, addDefaultToOptions(options, i18nKey));

// When translating new text, make sure to update the translations too:
// https://mdcollab.atlassian.net/wiki/spaces/engineering/pages/680329381/Localization
const useTranslation = (namespace?: I18nNamespace): I18n => {
  const i18nWithBadInterpolation = nextUseTranslation(namespace);

  return useMemo<I18n>(
    () => ({
      t: fixInterpolation(i18nWithBadInterpolation.t),
      lang: i18nWithBadInterpolation.lang,
    }),
    [i18nWithBadInterpolation],
  );
};

export {i18n, useTranslation, Trans, TransText, setLanguage};
